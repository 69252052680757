import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo";

const metrics = [
  {
    id: 1,
    stat: "S$3,000",
    emphasis: "Last page",
    rest: "- Full page advertisement.",
  },
  {
    id: 2,
    stat: "S$2,000",
    emphasis: "Second page",
    rest: "- Full page advertisement.",
  },
  {
    id: 3,
    stat: "S$2,000",
    emphasis: "Second last page",
    rest: "- Full page advertisement.",
  },
  {
    id: 4,
    stat: "S$1,500",
    emphasis: "Middle page",
    rest: "- Full page advertisement",
  },
  {
    id: 5,
    stat: "S$1000",
    emphasis: "Middle page",
    rest: "- Half page advertisement",
  },
];

const Advertisement = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Sponsorship opportunities"
          description="SWA welcome sponsorships
                      and financial support from individuals and corporate
                      member volunteers for our charity efforts."
        />
        <section className="max-w-5xl px-2 pt-6 mx-auto sm:w-4/5">
          <div className="relative mb-10 bg-swa-2">
            <div className="absolute bottom-0 w-full h-80 xl:inset-0 xl:h-full">
              <div className="w-full h-full xl:grid xl:grid-cols-2">
                <div className="h-full xl:relative xl:col-start-2">
                  <img
                    className="object-cover w-full h-full opacity-25 xl:absolute xl:inset-0"
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636687752/charityDinnerPage/ym853e0wmihc3ovixr1o.jpg"
                    alt="SWA Charity Gala Dinner"
                  />
                  <div
                    aria-hidden="true"
                    className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-swa-2 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                  />
                </div>
              </div>
            </div>
            <div className="max-w-4xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
              <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
                <h2 className="text-sm font-semibold tracking-wide uppercase text-swa-4">
                  Advertisement
                </h2>
                <p className="mt-3 text-3xl font-extrabold text-white">
                  46th SWA Charity Gala Dinner 2023 Souvenir Programme E-Booklet
                </p>

                {/* <p className="mt-5 text-lg text-gray-300">
                  Rhoncus sagittis risus arcu erat lectus bibendum. Ut in
                  adipiscing quis in viverra tristique sem. Ornare feugiat
                  viverra eleifend fusce orci in quis amet. Sit in et vitae
                  tortor, massa. Dapibus laoreet amet lacus nibh integer quis.
                  Eu vulputate diam sit tellus quis at.
                </p> */}
                <div className="grid grid-cols-1 mt-12 gap-y-12 gap-x-6 sm:grid-cols-2">
                  {metrics.map(item => (
                    <p key={item.id}>
                      <span className="block text-2xl font-bold text-white">
                        {item.stat}
                      </span>
                      <span className="block mt-1 text-base text-gray-300">
                        <span className="font-medium text-white">
                          {item.emphasis}
                        </span>{" "}
                        {item.rest}
                      </span>
                    </p>
                  ))}
                </div>
                <p className="mt-10 text-xl text-center text-white">
                  46th SWA Charity Gala Dinner Souvenir Programme E-Booklet
                </p>
                <img
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1685849818/participatePage/vmeelydffm3zwf8jwmea.jpg"
                  alt="MSPI 2019"
                  className="w-8/12 p-2 mx-auto"
                />
                {/* <h2 className="mt-8 text-lg text-white sm:text-lg">
                  <span className="block">
                    Help support our charity and community initiatives through
                    an advertisment slot on our 45th SWA Annual Charity Gala
                    Dinner Sounvenir Programme Booklet.
                  </span>

                </h2>
                <div className="flex mt-8">
                  <div className="inline-flex rounded-md shadow">
                    <Link
                      to="/forms/contact"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                    >
                      Contact us for more information and bookings
                    </Link>
                  </div>
                </div> */}
                {/* <h2 className="mt-8 text-lg text-center text-white">
                  <span className="block">Sponsorships is closed.</span>
                  Please contact us if you would like to discuss other
                  sponsorship opportunities.
               
                </h2> */}
                <div className="flex mt-4">
                  <div className="inline-flex mx-auto rounded-md shadow">
                    <Link
                      to="/forms/contact"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="px-4 py-8 mx-auto my-12 border border-gray-300 rounded-lg max-w-7xl sm:px-6">
            <h2 className="text-xl text-gray-600 sm:text-xl">
              <span className="block">Other sponsorship types available</span>
        
            </h2>
            <div className="flex mt-4">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/participate/corporate"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium border border-transparent rounded-md text-swa-2 bg-swa-4 hover:bg-swa-3"
                >
                  Corporate sponsorship
                </Link>
              </div>
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/participate/table"
                  className="inline-flex items-center justify-center px-5 py-3 ml-3 text-base font-medium border border-transparent rounded-md text-swa-2 bg-swa-4 hover:bg-swa-3"
                >
                  Table sponsorship
                </Link>
              </div>
            </div>
            <h2 className="mt-8 text-lg text-gray-600 sm:text-lg">
              <span className="block">
                Please contact us if you have any questions regarding the
                sponsorships
              </span>
          
            </h2>
            <div className="flex mt-4">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/forms/contact"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                >
                  Contact us
                </Link>
              </div>
            </div>
          </div> */}
        </section>
      </Layout>
    </>
  );
};

export default Advertisement;
